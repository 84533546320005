import {
  Box,
  Text,
  Heading,
  Input,
  InputGroup,
  FormControl,
  FormLabel,
  Flex,
  VStack,
  Link,
  Center,
  InputRightElement,
  Container,
} from "@chakra-ui/react"
import { observer } from "mobx-react"
import { useMst } from "../../lib/hooks/use-mst"
import { useState, useEffect } from "react"
import { BrandButton, LinkButton } from "./shared"
import { useRouter } from "next/router"

export const Login = observer(() => {
  const {
    sessionStore: { loginForm, isLoggedIn, submitLoginForm },
  } = useMst()
  const [validationMessage, setValidationMessage] = useState("")
  const router = useRouter()
  const { password, setPassword, setEmail, email, isValid } = loginForm
  const [passwordType, setPasswordType] = useState("password")

  const handleLogin = async (event) => {
    event.preventDefault()
    if (loginForm.isValid) {
      const success = await submitLoginForm()
      if (success) {
        const nextPage = decodeURIComponent((router.query.nextPage || "/") as string)
        router.push(nextPage)
      }
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin(event)
    }
  }

  useEffect(() => {
    if (isLoggedIn) {
      const nextPage = decodeURIComponent((router.query.nextPage || "/") as string)
      if (window.location.pathname !== nextPage && window.location.href !== nextPage) router.push(nextPage)
    }
  }, [isLoggedIn])

  const handleShowPassword = () => {
    if (passwordType == "password") {
      setPasswordType("text")
    } else {
      setPasswordType("password")
    }
  }

  const validateEmail = (email) => {
    var re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  if (isLoggedIn) {
    return (
      <Center>
        <Text pt="1rem" pb="2rem" color="grey">
          You are currently logged in.
        </Text>
      </Center>
    )
  }

  return (
    <Container maxW="container.md" p={4}>
      <Heading size="xl">Please Login</Heading>
      <Text pt="1rem" pb="2rem" color="grey">
        You must have an account with Anipet to view our website. <br></br>
        If you&apos;re having problems logging in please contact our Customer Care Team.
      </Text>
      <form autoComplete="new-password" onSubmit={handleLogin}>
        <FormControl color="grey" w="70%">
          <Flex>
            <FormLabel htmlFor="email" w="50%" pb="2rem">
              E-mail Address*
            </FormLabel>
            <InputGroup>
              <VStack w="full">
                <Input
                  name="email"
                  autoComplete="new-password"
                  id="email"
                  type="email"
                  onChange={(e) => {
                    if (validateEmail(e.target.value)) {
                      setEmail(e.target.value)
                      setValidationMessage("")
                    } else {
                      setValidationMessage("Please enter a valid email address.")
                    }
                  }}
                />
                {validationMessage && (
                  <Box color="red" textAlign="left" pb={5} w="full">
                    {validationMessage}
                  </Box>
                )}
              </VStack>
            </InputGroup>
          </Flex>
          <Flex>
            <FormLabel htmlFor="password" w="50%" pb="2rem">
              Password*
            </FormLabel>
            <InputGroup>
              <Input
                name="password"
                autoComplete="new-password"
                id="password"
                type={passwordType}
                value={password}
                onKeyPress={handleKeyPress}
                onChange={(e) => setPassword(e.target.value)}
              />
              <InputRightElement onClick={handleShowPassword} className="InputLeft" textAlign="right">
                <Link color="utility.link" w="full" mr={{ sm: 5, lg: 10 }}>
                  Show
                </Link>
              </InputRightElement>
            </InputGroup>
          </Flex>

          <Flex
            mt={1}
            pt="2rem"
            w="auto"
            justifyContent={"space-between"}
            wrap={"wrap"}
            maxW={"700px"}
            flexDirection="row-reverse"
          >
            <BrandButton disabled={!isValid} onClick={handleLogin}>
              LOGIN
            </BrandButton>
            <Box>
              <LinkButton href="/forgot-password">Password Reset/Register</LinkButton>
            </Box>
          </Flex>
        </FormControl>
      </form>
      <Box py={6}>
        <Text color="grey">
          Don&apos;t have an account? Please{" "}
          <Link href="/info/contact-us" color="utility.link">
            contact us.
          </Link>
        </Text>
      </Box>
    </Container>
  )
})
