import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { Login } from "../src/app/components/login"

//https://next-auth.js.org/tutorials/securing-pages-and-api-routes
export default function LoginPage() {
  return <Login />
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  }
}
